/** @jsx jsx */
import { jsx } from "theme-ui";
import arrowIllustration from "@src/assets/images/arrow.svg";
import bookIllustration from "@src/assets/images/book.svg";
import userIllustration from "@src/assets/images/users.svg";
import searchIllustration from "@src/assets/images/search.svg";
import sendIllustration from "@src/assets/images/send.svg";
import { rem } from "@src/theme";

const ArrowBg = ({
  children,
  type,
}: {
  type?: string;
  children: React.ReactNode;
}) => {
  const types = {
    book: bookIllustration,
    arrow: arrowIllustration,
    user: userIllustration,
    search: searchIllustration,
    send: sendIllustration,
  };

  const img = type ? types[type] : types["arrow"];

  return (
    <div
      sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: ["100%", null, "right"],
        backgroundPositionY: ["top", null, "top"],
        pt: [rem(25), null, rem(70)],
        pb: [rem(10), null, rem(30)],
        backgroundSize: ["50%", null, "34%"],
        p: {
          maxWidth: rem(700),
        },
      }}
    >
      {children}
    </div>
  );
};

export default ArrowBg;
