import { formatCraftType } from "@src/helpers/utils";

export const mapContentPage = (craft) => {
    return {
        title: craft.entry.title,
        description: craft.entry.description || "",
        body: craft.entry.body || "",
      };
  };
  
export const mapCraftRelatedEntry = (entry) => {
    const mapTag = ({ title }) => title;
    return {
      title: entry.title || "",
      slug: entry.slug || "",
      description: entry.description || "",
      video: entry.embedVideo || "",
      img:
        entry.img && entry.img.length
          ? {
              src: entry.img[0].url,
              alt: entry.img[0].title,
            }
          : null,
      type: formatCraftType(entry.typeHandle),
      socialImpacts:
        entry.socialImpactsTags && entry.socialImpactsTags.length
          ? entry.socialImpactsTags.map(mapTag)
          : [],
      populationGroups:
        entry.populationGroupTags && entry.populationGroupTags.length
          ? entry.populationGroupTags.map(mapTag)
          : [],
      industries:
        entry.industriesTags && entry.industriesTags.length
          ? entry.industriesTags.map(mapTag)
          : [],
      locations:
        entry.locationsTags && entry.locationsTags.length
          ? entry.locationsTags.map(mapTag)
          : [],
      insightsFor:
        entry.insightsForTags && entry.insightsForTags.length
          ? entry.insightsForTags.map(mapTag)
          : [],
    };
  };