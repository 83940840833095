export const kebabize = (str) => {
  return str
    .split("")
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
};

export const trimSentence = (str, cutOff) => {
  if (str.length > cutOff) {
    //trim the string to the maximum length
    var trimmedString = str.substr(0, cutOff);

    //re-trim if we are in the middle of a word and
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return trimmedString + "...";
  } else {
    return str;
  }
};


export const formatCraftType = (type) => type
? type
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, function(str) {
      return str.toUpperCase();
    })
: ""