/** @jsx jsx */
import { jsx } from "theme-ui";
import Container from "@components/Container";
import { rem } from "@src/theme";
import Layout from "@components/Layout";
import ArrowBg from "@components/ArrowBg";
import SEO from "@components/seo";
import BackBtn from "@components/BackBtn";
interface ComponentProps {
  title: string;
  description?: string;
  body: string;
  bgIcon: string;
  rest?: React.ReactNode;
  location?: any;
}
const ContentTemplate = ({
  title,
  description,
  body,
  bgIcon = "arrow",
  rest,
  ...props
}: ComponentProps) => {
  const seoData = {
    metaTitle: title,
    metaDataDescription: description,
  };
  return (
    <Layout>
      <SEO metadata={seoData} location={props.location} />
      <div
        sx={{
          bg: "lightGrey",
        }}
      >
        <Container>
          <ArrowBg type={bgIcon}>
            <div
              sx={{
                pt: [rem(20), rem(40), rem(60)],
                pb: 20,

                button: {
                  mb: rem(20),
                },
              }}
            >
              <BackBtn />
              <h1
                sx={{
                  variant: "typography.headingXXL",
                  mb: 0,
                }}
              >
                {title}
              </h1>
              {description && (
                <p
                  sx={{
                    mb: [rem(16), null, rem(24)],
                    fontSize: [rem(16), null, rem(22)],
                    lineHeight: 1.3,
                  }}
                >
                  {" "}
                  {description}
                </p>
              )}
            </div>
          </ArrowBg>
        </Container>
      </div>

      <div
        sx={{
          pt: [rem(30), rem(50), rem(60)],
          pb: [rem(40), rem(60), rem(80)],
        }}
      >
        <Container>
          <div
            dangerouslySetInnerHTML={{ __html: body }}
            sx={{
              "p, li, a": { variant: "typography.paragraphM" },
              width: ["100%"],
              maxWidth: [null, rem(700), null],
              "p:first-child": {
                mt: 0,
              },
              h2: {
                fontSize: [rem(18), null, rem(24)],
                mt: rem(40),
                fontWeight: 600,
              },
              h3: {
                mt: rem(40),
                mb: rem(14),
              },

              a: {
                textDecoration: ["underline"],

                textDecorationColor: "inherit",

                "&:hover": {
                  textDecorationColor: ["transparent"],
                },
              },

              li: {
                listStyle: ["inside"],
                mb: rem(10),
                textIndent: "-1em",
                pl: "1em",
              },
              strong: {
                fontWeight: 600,
              },

              ol: {
                li: {
                  paddingInlineStart: ["20px"],
                },
              },
            }}
          ></div>
        </Container>

        {rest}
      </div>
    </Layout>
  );
};

export default ContentTemplate;
